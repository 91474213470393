// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_sk";
export var lbContainerInner = "w_sl";
export var movingForwards = "w_sm";
export var movingForwardsOther = "w_sn";
export var movingBackwards = "w_sp";
export var movingBackwardsOther = "w_sq";
export var lbImage = "w_sr";
export var lbOtherImage = "w_ss";
export var prevButton = "w_st";
export var nextButton = "w_sv";
export var closing = "w_sw";
export var appear = "w_sx";